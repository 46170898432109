/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { isSameValue } from "../utils/string";
/**
 * ? Should this util method be exposed from commonUtil
 * @param  {0bject} data
 * @param  {boolean} value
 * @param  {string} field
 * @param  {string} tellUsMoreServiceId
 */
function CheckboxCell({ data, value, field, tellUsMoreServiceId }) {
  const [checked, setChecked] = useState(Number(value));
  // console.log("CheckboxCell", node, value, field);
  const { make, globalRepairOpFlag, serviceKind } = data;

  useEffect(
    function updateChecked() {
      if (checked != value) {
        setChecked(value);
      }
    },
    [value]
  );

  const disabled =
    field === "applyShopSupplies" ||
    field === "excludeDiscount" ||
    (field === "commentsRequired" &&
      (globalRepairOpFlag || serviceKind !== "repair")) ||
    (field === "mandatoryInAppt" && globalRepairOpFlag) ||
    (field === "unscheduledDefault" && make === "ANY") ||
    (data.serviceId && isSameValue(tellUsMoreServiceId, data.serviceId)) ||
    (field === "defaultLaborRateFlag" && data.defaultLaborRateFlag) ||
    (field === "defaultPayType" && data.defaultPayType == "1") ||
    (field === "isLaborGrid" && (data.rateType || data.warrantyType));
  const diasabledLabor =
    (field === "defaultLaborRateFlag" && data.defaultLaborRateFlag) ||
    (field === "defaultPayType" &&
      data.defaultPayType &&
      data.defaultPayType == "1");
  return (
    <div className="xmm-checkbox-container">
      <input
        className="form-checkbox"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => {
          const newChecked = !checked;
          setChecked(newChecked);
          if (data && field) {
            data[field] = newChecked;
            const value = newChecked | 0;
            const detail = { data, field, value };
            window.dispatchEvent(
              new CustomEvent("saveCellEditEvent", {
                detail,
                bubbles: true,
                cancelable: true
              })
            );
          }
          // console.log(data);
        }}
      />
      <span
        className={"xmm-checkmark" + (diasabledLabor ? " disabled-labor" : "")}
      />
      <span className="label-checkbox" />
    </div>
  );
}

export default CheckboxCell;
